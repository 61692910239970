<style lang="scss">
.select-box {
  border: 1px solid #d1d1d1;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  @apply text-center;
  color: #000;
  padding: 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 330px;

  .sticker {
    position: absolute;
    top: -25px;
    left: -25px;
    background: #FAB413;
    border-radius: 100%;
    padding: 18px 12px;
    font-size: 12px;
    font-weight: 900;
    transform: rotate(-30deg);
    text-align: center;
  }

  .green-btn {
    padding-right: 30px;
    padding-left: 30px;
  }

  &.active {
    border: 2px solid #FAB413;
  }

  ul {
    text-align: left;
    font-size: 16px;
    margin-left: 45px;

    li {
      margin-bottom: 10px;
    }
  }
}

.history {
  border: 1px solid #D1D1D1;
  border-radius: 4px;

  .header {
    background: #FBFBFB;
    padding: 10px 15px;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #EDEDED;
  }

  .billing {
    display: flex;
    border-bottom: 1px solid #EDEDED;
    padding: 15px;

    &:last-child {
      border: none;
    }

    &.hidden {
      display: none;
    }
  }

  .showMore {
    //border-top:1px solid #EDEDED;
  }
}
</style>

<template>
  <div class="lg:mt-5 lg:px-4 max-w-2xl mx-auto side-nav short">
    <vue-headful
      title="Settings - Plan & Billing | Octoa"
    />

    <modal
      name="billingPeriodModal"
      class="modal"
      width="100%"
      :max-width="750"
      height="auto"
      :adaptive="true"
    >
      <div class="flex px-2 justify-between">
        <div class="title">
          Change billing period
        </div>
        <div
          class="close"
          @click="$modal.hide('billingPeriodModal')"
        />
      </div>

      <div class="flex flex-wrap px-2 mt-2">
        <div class="radio-container mt-4">
          <input
            id="annually"
            v-model="plan"
            type="radio"
            value="annually"
            name="plan"
          >
          <div class="border">
            <label
              for="annually"
              class="radio-label cursor-pointer"
            >
              <div class="flex w-full justify-between">
                <div>
                  Billed yearly <span v-if="user.me.company.subscription_terms == 'annually'">(current billing period)</span>
                  <span v-if="user.me.company.subscription_terms == 'monthly'"><div class="sub-text">Your new subscription will start on {{ user.me.company.subscription_next_payment_date | humanDate(timezone) }}. </div></span>
                  <span v-else><div class="sub-text">Your current plan</div></span>
                </div>
                <!-- <div class="text-right">
                  <strong class="text-black">€120</strong> <br>
                  <span class="text-grey-semi-light text-sm">per year</span>
                </div> -->
              </div>
            </label>
            <div class="check" />
          </div>
        </div>
      </div>

      <div class="flex px-2 mt-2">
        <div class="radio-container mt-4">
          <input
            id="monthly"
            v-model="plan"
            type="radio"
            value="monthly"
            name="plan"
          >
          <div class="border">
            <label
              for="monthly"
              class="radio-label cursor-pointer"
            >
              <div class="flex w-full justify-between">
                <div>
                  Billed monthly <span v-if="user.me.company.subscription_terms == 'monthly'">(current billing period)</span>
                  <span v-if="user.me.company.subscription_terms == 'annually'"><div class="sub-text">Your new subscription will start on {{ user.me.company.subscription_next_payment_date | humanDate(timezone) }}. </div></span>
                  <span v-else><div class="sub-text">Your current plan</div></span>
                </div>
                <!-- <div class="text-right">
                  <strong class="text-black">€12</strong> <br>
                  <span class="text-grey-semi-light text-sm">per month</span>
                </div> -->
              </div>
            </label>
            <div class="check" />
          </div>
        </div>
      </div>

      <div class="flex px-2 mt-4 flex-wrap mt-2 items-center justify-end">
        <div
          class="text-green underline mr-5"
          @click="$modal.hide('billingPeriodModal')"
        >
          Cancel
        </div>
        <div class="">
          <button
            class="green-btn w-32"
            @click="updateBillingPeriod"
          >
            Confirm
          </button>
        </div>
      </div>
    </modal>

    <div class="flex flex-wrap mb-5">
      <div
        v-show="!$route.meta.hideHeader"
        class="w-full"
        :class="{'lg:w-1/4' : !$route.meta.hideHeader}"
      >
        <setting-menu />
      </div>

      <div
        class="w-full"
        :class="{'lg:w-3/4' : !$route.meta.hideHeader}"
      >
        <div
          v-if="loading"
          class="text-center"
        >
          <img
            src="@/assets/img/icons/loader.svg"
            width="50"
            class="mt-32"
          >
        </div>
        <div
          v-else
          class="mb-20 md:mb-0"
        >
          <div class="bg-white h-full mx-4 my-4 px-4 py-4 lg:mx-0 lg:my-0 lg:px-8 lg:py-8 border-grey-lighter border rounded">
            <h2 class="font-bold mb-5">
              Plan & Billing
              <div
                v-if="paymentFail"
                class="bg-red ml-2 px-3 py-2 inline-block text-white text-sm my-3 text-center rounded"
              >
                Payment failed, please try again.
              </div>
            </h2>
            <div
              v-if="!user.me.company.is_subscription_active"
              class="text-xl text-grey-darker mt-2"
            >
              <div
                v-show="!$route.meta.hideHeader"
                class="leading-normal"
              >
                <span class="underline bg-blue-washed px-1 py-1 "> {{ user.company.trial_end_at | trialDaysLeft }} days left in your trial.</span> Upgrade Octoa today and run your business better.
              </div>

              <div
                v-show="$route.meta.hideHeader"
                class="text-center font-bold text-black text-base my-10"
              >
                Activate your discount now
              </div>

              <div
                v-show="!$route.meta.hideHeader"
                class="text-center font-bold text-black text-base my-10"
              >
                Which payment options fits you best?
              </div>

              <div
                v-if="discountYearlyAmount > 0 || discountMonthlyAmount > 0"
                class="mb-12 text-center"
              >
                <div class="static-tag discount-code">
                  Discount code applied: <span>{{ discountCode }}</span>
                </div>
              </div>

              <div class="flex flex-wrap justify-center">
                <div class="w-full md:w-1/2  mb-5 lg:mb-0">
                  <div
                    class="select-box active"
                  >
                    <div class="sticker">
                      BEST <br> VALUE
                    </div>
                    <div class="font-bold">
                      Pay annually
                    </div>
                    <div
                      class="green-btn my-6 inline-block"
                      @click="choosePlan('annually')"
                    >
                      Choose this plan
                    </div>
                    <ul>
                      <li>
                        <img
                          src="@/assets/img/icons/checkmark.svg"
                          width="15"
                          class="mr-2"
                        >
                        <span v-if="discountYearlyAmount == 0">
                          <strike class="text-grey-semi-light">€252</strike>&nbsp;<strong>€210/year*</strong>
                        </span>
                        <span v-else>
                          <strike>€252</strike>&nbsp;<strong>€{{ discountYearlyAmount }}/year*</strong>
                        </span>
                      </li>
                      <li>
                        <img
                          src="@/assets/img/icons/checkmark.svg"
                          width="15"
                          class="mr-2"
                        > Save 20% off
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="w-full md:w-1/2  pb-5">
                  <div
                    class="select-box"
                  >
                    <div class="font-bold">
                      Pay monthly
                    </div>
                    <div
                      class="green-btn my-6 inline-block"
                      @click="choosePlan('monthly')"
                    >
                      Choose this plan
                    </div>
                    <ul>
                      <li>
                        <img
                          src="@/assets/img/icons/checkmark.svg"
                          width="15"
                          class="mr-2"
                        >
                        <span v-if="discountMonthlyAmount == 0">
                          <strong>€21/month*</strong>
                        </span>
                        <span v-else>
                          <strike>€21</strike>&nbsp;<strong>€{{ discountMonthlyAmount }}/month*</strong>
                        </span>
                      </li>
                      <li>
                        <img
                          src="@/assets/img/icons/checkmark.svg"
                          width="15"
                          class="mr-2"
                        > Cancel any time
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                v-show="!$route.meta.hideHeader"
                class="flex py-5 justify-center items-center"
              >
                <div>
                  <input
                    id="vat"
                    ref="vat"
                    v-model="discountCode"
                    class="w-full"
                    name="discountCode"
                    type="text"
                    placeholder="Coupon code"
                    :disabled="discountYearlyAmount > 0 || discountMonthlyAmount > 0"
                  >
                </div>
                <div class="text-center px-4">
                  <a
                    v-show="discountYearlyAmount == 0 && discountMonthlyAmount == 0"
                    class="text-green pb-1 inline-block cursor-pointer underline text-sm"
                    @click="applyDiscountCode(true)"
                  >
                    Apply
                  </a>
                  <a
                    v-show="discountYearlyAmount > 0 || discountMonthlyAmount > 0"
                    class="text-green pb-1 inline-block cursor-pointer underline text-sm"
                    @click="removeDiscountCode"
                  >
                    Remove
                  </a>
                </div>
              </div>
              <p class="text-sm mt-3 max-w-md font-medium leading-normal text-black mx-auto">
                * Price excluding VAT. Exemption available for those with valid VAT number (excluding Netherlands) or when based outside the EU.
              </p>

              <div
                v-show="$route.meta.hideHeader"
                class="my-10 cursor-pointer underline text-center text-grey-darker text-sm my-10"
                @click="skipDiscountCode"
              >
                Skip for now
              </div>
            </div>
            <div
              v-else
              class="mt-8 mb-5"
            >
              <div class="flex justify-between items-center">
                <div class="flex items-center">
                  <div class="mr-5">
                    <img
                      src="@/assets/img/octoa.svg"
                      width="36"
                    >
                  </div>
                  <div class="text-base">
                    Octoa <span v-if="user.me.company.subscription_terms == 'annually'">annual</span><span v-else>monthly</span> plan
                    <div class="mt-1 text-sm text-grey-semi-light mr-2">
                      <div v-if="!user.me.company.subscription_end_at">
                        Renew on {{ user.me.company.subscription_next_payment_date | humanDate(timezone) }} at
                        <span v-if="user.me.company.subscription_terms == 'monthly'">
                          <vue-numeric
                            currency-symbol-position="prefix"
                            :precision="2"
                            read-only
                            currency="€"
                            decimal-separator=","
                            thousand-separator="."
                            :min="0"
                            :value="user.me.company.subscription_amount"
                          /> per month
                        </span>
                        <span v-else><vue-numeric
                          currency-symbol-position="prefix"
                          :precision="2"
                          read-only
                          currency="€"
                          decimal-separator=","
                          thousand-separator="."
                          :min="0"
                          :value="user.me.company.subscription_amount"
                        /> per year</span>
                      </div>
                      <div v-else>
                        You will no longer able to access your account from {{ user.me.company.subscription_end_at | humanDate(timezone) }}.
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    v-if="!user.me.company.subscription_end_at"
                    class="text-green underline cursor-pointer"
                    @click="cancelPlan"
                  >
                    Cancel
                  </div>
                  <div
                    v-else
                    class="green-btn"
                    @click="reactivatePlan"
                  >
                    Reactivate
                  </div>
                </div>
              </div>

              <hr class="my-6">
              <div class="flex justify-between text-sm ">
                <div>
                  Payment method
                </div>
                <div>
                  Credit Card
                  <router-link
                    to="/settings/plan-and-billing/payment/update"
                    class="ml-5 text-green underline"
                  >
                    Update
                  </router-link>
                </div>
              </div>
              <hr class="my-6">
              <div class="flex justify-between">
                <div>
                  Billing period
                </div>
                <div>
                  Plan billed {{ user.me.company.subscription_terms }}
                  <a
                    class="ml-5 text-green underline cursor-pointer"
                    @click="showBillingPeriodModal"
                  >Change</a>
                </div>
              </div>
              <hr class="my-6">
              <div class="flex justify-between">
                <div>
                  Billing history
                  <div class="mt-1 text-sm text-grey-semi-light">
                    View invoices and receipts for all payments made on your Octoa account.
                  </div>
                </div>
              </div>

              <div class="history mt-8">
                <div class="header flex">
                  <div class="w-3/12">
                    Date
                  </div>
                  <div class="w-4/12">
                    Description
                  </div>
                  <div class="w-5/12 text-right">
                    Amount
                  </div>
                </div>

                <div
                  v-for="(billing, index) in billings"
                  :key="index"
                  class="billing items-center"
                  :class="{'hidden': (!showMoreBilling && index > 4) }"
                >
                  <div class="w-3/12">
                    {{ billing.created_at | humanDate(timezone) }}
                  </div>
                  <div class="w-4/12">
                    {{ billing.description }}
                  </div>
                  <div class="w-5/12 text-right">
                    <a
                      v-if="billing.invoice"
                      :href="billing.invoice"
                      target="_blank"
                      class="mr-2 underline cursor-pointer"
                    >Invoice</a>
                    <span class="inline-block mr-2"><svg
                      width="14"
                      height="14"
                      viewBox="0 0 16 13"
                      xmlns="http://www.w3.org/2000/svg"
                    ><path
                      fill="#0fce83"
                      d="M13.933 3.09a.35.35 0 0 0-.495.026l-7.236 8.012-3.757-3.393a.35.35 0 0 0-.495.025l-.86.951a.35.35 0 0 0 .026.495l4.96 4.48a.34.34 0 0 0 .17.082.346.346 0 0 0 .34-.107l8.324-9.217a.35.35 0 0 0-.026-.494l-.951-.86z"
                    /></svg></span>
                    <span>€{{ billing.amount }}</span>
                    <span
                      v-if="billing.is_vat"
                      class="text-grey-semi-light"
                    > (VAT included)</span>
                  </div>
                </div>

                <div
                  v-if="(!showMoreBilling && billings.length > 5)"
                  class="showMore text-green underline text-center py-5 cursor-pointer"
                  @click="doShowMoreBilling"
                >
                  View more billing history
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'

export default {
  name: 'SettingsPlanBilling',
  data() {
    return {
      loading: true,
      currency: {
        sign: '€'
      },
      isSaving: false,
      priceText: '€144/year',
      plan: 'annually',
      isAuth: auth.check(),
      user: auth.user(),
      timezone: '',
      billings: {},
      showMoreBilling: false,
      paymentFail: false,
      discountCode: null,
      discountYearlyAmount: 0,
      discountMonthlyAmount: 0,
    }
  },
  created() {
    this.currency = this.user.companySettings.currency
    this.timezone = this.user.companySettings.timezone
  },
  async mounted() {

    await this.refresh()
    if (!this.user.me.company.is_subscription_active && this.$route.query.payment == 'done') {
      this.paymentFail = true
    }

    if (this.user.me.discount_code) {
      this.discountCode = this.user.me.discount_code
      await this.applyDiscountCode(false)
    }

    try {
      const {data} = await this.$api.get('billing').all(this.user.company.id)
      this.billings = data
    } catch (e) {
      this.$toasted.global.api_error(e)
    }

    this.loading = false

  },
  methods: {
    async applyDiscountCode(notification) {
      this.$toasted.clear()
      try {
        const {data} = await this.$api.get('discountCode').apply(this.user.company.id, {discountCode: this.discountCode})
        this.discountYearlyAmount = (data.yearly_amount > 0) ? Number(210) - Number(data.yearly_amount) : 0
        this.discountMonthlyAmount = (data.monthly_amount > 0) ? Number(21) - Number(data.monthly_amount) : 0
        this.discountYearlyAmount = this.discountYearlyAmount.toFixed(2)
        this.discountMonthlyAmount = this.discountMonthlyAmount.toFixed(2)
        await this.refresh()
        if (notification) {
          this.$toasted.global.general_success({
            message: 'Discount code applied.'
          })
        }
      } catch (e) {
        if (notification) {
          this.$toasted.global.general_error({
            message: 'Oops, something is wrong with your code.'
          })
        }
        console.log('Code error.')
      }
    },
    async removeDiscountCode() {
      this.$toasted.clear()
      try {
        const {data} = await this.$api.get('discountCode').remove(this.user.company.id)

        this.discountCode = null
        this.discountMonthlyAmount = 0
        this.discountYearlyAmount = 0
        await this.refresh()
        this.$toasted.global.general_success({
          message: 'Discount code removed.'
        })

      } catch (e) {
        this.$toasted.global.api_error(e)
      }
    },
    async refresh() {
      await auth.refreshUser()
      this.user = auth.user()
      this.plan = this.user.me.company.subscription_terms
    },
    async cancelPlan() {
      const canDelete = await this.$alert.confirm({
        title: 'Cancel plan',
        text: `Your plan will cancel on ${this.$options.filters.humanDate(this.user.company.subscription_next_payment_date, this.timezone)}.`,
      })
      if (canDelete) {
        try {
          const {data} = await this.$api.get('subscription').cancel(this.user.company.id)
          await this.refresh()
        } catch (e) {
          this.$toasted.global.api_error(e)
        }
      }
    },
    async reactivatePlan() {
      const can = await this.$alert.confirm({
        title: 'Reactivate plan',
        text: `Your plan will renew on ${this.$options.filters.humanDate(this.user.company.subscription_next_payment_date, this.timezone)}.`,
      })
      if (can) {
        try {
          const {data} = await this.$api.get('subscription').reactivateWhileActive(this.user.company.id)
          await this.refresh()
        } catch (e) {
          this.$toasted.global.api_error(e)
        }
      }
    },
    async updateBillingPeriod() {
      try {
        const {data} = await this.$api.get('subscription').updatePeriod(this.user.company.id, {plan: this.plan})
        await this.refresh()
        this.$modal.hide('billingPeriodModal')
      } catch (e) {
        this.$toasted.global.api_error(e)
      }
    },
    skipDiscountCode() {
      this.$router.replace({name: 'Dashboard', query: {page: 'intro'}})
    },
    showBillingPeriodModal() {
      this.$modal.show('billingPeriodModal')
    },
    doShowMoreBilling() {
      this.showMoreBilling = true
    },
    choosePlan(plan) {
      if (plan == 'monthly') {
        this.priceText = '€21/month'
      } else {
        this.priceText = '€210/year'
      }
      this.$router.push(`/settings/plan-and-billing/payment/${plan}`)

    },
    isActive(plan) {
      return plan === this.plan
    },
  },
}
</script>
